.contactus .form-control {
    display: block;
    padding: 75px 15px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 0px solid #ced4da12;
    border-radius: 5px;
    margin-top: 30px;
    width: 600px;
  }
  