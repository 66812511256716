.MuiPagination-ul{
    justify-content: center;
}

.loaderss{
    padding: 15% 0%;
    text-align: center;
}
.loaderss svg{
  margin: auto !important;
}

