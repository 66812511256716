.swal-title{
    color: black !important;
}
.swal-button {
    background-color: #071e43 !important;
}
.swal-icon--success__ring {
    border: 4px solid hsl(98deg 99% 41%) !important;
}
.displays{
    display: flex;
    align-items: baseline;
}

.displays .form-check-input[type=checkbox] {
    margin-top: 13px;
}
@media only screen and (max-width: 768px) {
    .displays{
        display: unset;
    }
  }